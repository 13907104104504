import moment, { Moment } from "moment-timezone";

export interface TourDates {
  startDate: Moment;
  endDate: Moment;
}

export const calculateTourDates = (
  reservation: Reservation,
  operator: Operator
): TourDates => {
  const startDate = moment.tz(
    `${reservation.tourStartDate} ${reservation.tourOptionTimePricing.time}`,
    "YYYY-MM-DD h:mm a",
    operator.timezone
  );

  const duration = reservation.tour.productContent?.productSetup.duration;

  const endDate = startDate.clone().add({
    days: duration?.days || 0,
    hours: duration?.hours || 0,
  });

  return {
    startDate,
    endDate,
  };
};
