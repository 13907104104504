/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetIsBeforeCutoff
 */
export interface GetIsBeforeCutoff {
    /**
     * 
     * @type {boolean}
     * @memberof GetIsBeforeCutoff
     */
    cutoffSuccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetIsBeforeCutoff
     */
    rescheduleSuccess: boolean;
}

/**
 * Check if a given object implements the GetIsBeforeCutoff interface.
 */
export function instanceOfGetIsBeforeCutoff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cutoffSuccess" in value;
    isInstance = isInstance && "rescheduleSuccess" in value;

    return isInstance;
}

export function GetIsBeforeCutoffFromJSON(json: any): GetIsBeforeCutoff {
    return GetIsBeforeCutoffFromJSONTyped(json, false);
}

export function GetIsBeforeCutoffFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetIsBeforeCutoff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cutoffSuccess': json['cutoffSuccess'],
        'rescheduleSuccess': json['rescheduleSuccess'],
    };
}

export function GetIsBeforeCutoffToJSON(value?: GetIsBeforeCutoff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cutoffSuccess': value.cutoffSuccess,
        'rescheduleSuccess': value.rescheduleSuccess,
    };
}

