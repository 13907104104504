/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FixedTime } from './FixedTime';
import {
    FixedTimeFromJSON,
    FixedTimeFromJSONTyped,
    FixedTimeToJSON,
} from './FixedTime';
import type { RelativeTime } from './RelativeTime';
import {
    RelativeTimeFromJSON,
    RelativeTimeFromJSONTyped,
    RelativeTimeToJSON,
} from './RelativeTime';

/**
 * 
 * @export
 * @interface CutOffTime
 */
export interface CutOffTime {
    /**
     * 
     * @type {string}
     * @memberof CutOffTime
     */
    type: CutOffTimeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CutOffTime
     */
    time: string;
    /**
     * 
     * @type {number}
     * @memberof CutOffTime
     */
    daysCount: number;
    /**
     * 
     * @type {number}
     * @memberof CutOffTime
     */
    hoursCount: number;
}


/**
 * @export
 */
export const CutOffTimeTypeEnum = {
    Relative: 'relative'
} as const;
export type CutOffTimeTypeEnum = typeof CutOffTimeTypeEnum[keyof typeof CutOffTimeTypeEnum];


/**
 * Check if a given object implements the CutOffTime interface.
 */
export function instanceOfCutOffTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "daysCount" in value;
    isInstance = isInstance && "hoursCount" in value;

    return isInstance;
}

export function CutOffTimeFromJSON(json: any): CutOffTime {
    return CutOffTimeFromJSONTyped(json, false);
}

export function CutOffTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CutOffTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'time': json['time'],
        'daysCount': json['daysCount'],
        'hoursCount': json['hoursCount'],
    };
}

export function CutOffTimeToJSON(value?: CutOffTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'time': value.time,
        'daysCount': value.daysCount,
        'hoursCount': value.hoursCount,
    };
}

