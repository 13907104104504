interface Config {
  common: ConfigCommon;
  development: ConfigEnvironment;
  production: ConfigEnvironment;
}

interface ConfigCommon {
  googleAnalytics: ConfigGoogleAnalytics;
  defaultTheme: Theme;
  reservationsResultsLimit: number;
}

interface ConfigGoogleAnalytics {
  measurementId: string;
}

interface ConfigEnvironment {
  version: string;
  firebaseApp: FirebaseApp;
  buckets: Buckets;
  apiUrl: string;
  guest: ConfigGuest;
  utmExpirationHours: number;
}

interface FirebaseApp {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

interface Buckets {
  photos: string;
  publicUrl: string;
}

interface ConfigGuest {
  url: string;
}

export const config: Config = {
  common: {
    googleAnalytics: {
      measurementId: "G-X4PHSDB8WB",
    },
    defaultTheme: "findalocaltour",
    reservationsResultsLimit: 500,
  },
  development: {
    version: "0.204",
    firebaseApp: {
      apiKey: "AIzaSyCxpC2bjv2l2aXRYvYkt0OopI8pbZBeWMI",
      authDomain: "triphub-dev.firebaseapp.com",
      projectId: "triphub-dev",
      storageBucket: "triphub-dev.appspot.com",
      messagingSenderId: "140977139085",
      appId: "1:140977139085:web:35fcd4a5fe9c85c85de1c4",
    },
    buckets: {
      photos: "gs://triphub-dev-photos",
      publicUrl: "https://storage.googleapis.com/triphub-dev-photos",
    },
    apiUrl: "https://api-triphub-dev.web.app",
    guest: {
      url: "https://guest.tourpit.webuild.pe",
    },
    utmExpirationHours: 24,
  },
  production: {
    version: "0.235",
    firebaseApp: {
      apiKey: "AIzaSyBSiEAIEKzzkAlviRIqoHtMQMxBxAVY_YA",
      authDomain: "triphub-375615.firebaseapp.com",
      projectId: "triphub-375615",
      storageBucket: "triphub-375615.appspot.com",
      messagingSenderId: "921453573895",
      appId: "1:921453573895:web:36d97927ec8c2debb337a2",
    },
    buckets: {
      photos: "gs://triphub-375615-photos",
      publicUrl: "https://storage.googleapis.com/triphub-375615-photos",
    },
    apiUrl: "https://api.findalocaltour.com",
    guest: {
      url: "https://guest.tourpit.com",
    },
    utmExpirationHours: 24,
  },
};
