/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CountryISO,
} from '../models';
import {
    CountryISOFromJSON,
    CountryISOToJSON,
} from '../models';

/**
 * 
 */
export class CountriesApi extends runtime.BaseAPI {

    /**
     */
    async getCountriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CountryISO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CountryISOFromJSON));
    }

    /**
     */
    async getCountries(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CountryISO>> {
        const response = await this.getCountriesRaw(initOverrides);
        return await response.value();
    }

}
